/* rotate box */
.rm-rotate-box .flipper:hover .front,
.rm-rotate-box .flipper:hover .back {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: transform 1s cubic-bezier(0.3, 1, 0.6, 1);
}

.rm-rotate-box .to-left .front,
.rm-rotate-box .to-right .front {
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
}

.rm-rotate-box .to-top .front,
.rm-rotate-box .to-bottom .front {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
}

.rm-rotate-box .to-left .back {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}

.rm-rotate-box .to-left:hover .front {
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
}

.rm-rotate-box .to-left:hover .back {
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
}

.rm-rotate-box .to-right:hover .front {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}

.rm-rotate-box .to-right .back {
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
}

.rm-rotate-box .to-right:hover .back {
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
}

.rm-rotate-box .to-top .back {
    transform: rotateX(-180deg);
    -webkit-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
    -ms-transform: rotateX(-180deg);
}

.rm-rotate-box .to-top:hover .front {
    transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
}

.rm-rotate-box .to-top:hover .back {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
}

.rm-rotate-box .to-bottom .back {
    transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
}

.rm-rotate-box .to-bottom:hover .front {
    transform: rotateX(-180deg);
    -webkit-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
    -ms-transform: rotateX(-180deg);
}

.rm-rotate-box .to-bottom:hover .back {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
}

.rm-rotate-box .thumb-wrap {
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.rm-rotate-box .front,
.rm-rotate-box .back {
    background-size: cover;
    min-height: 315px;
    z-index: 1;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: inherit;
    transition: transform 0.8s cubic-bezier(0.5, 0.2, 0.2, 0.8);
}

.rm-rotate-box .back {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rm-rotate-box .back .content-wrap {
    top: 50%;
    bottom: inherit;
    left: 0;
    transform: translate3d(0, -50%, 0) scale(1);
    -webkit-transform: translate3d(0, -50%, 0) scale(1);
    -moz-transform: translate3d(0, -50%, 0) scale(1);
    -ms-transform: translate3d(0, -50%, 0) scale(1);
}

.rm-rotate-box .content-wrap {
    position: absolute;
    width: 100%;
    display: block;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: inherit;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 2;
    bottom: 55px;
    left: 0;
    transform: translate3d(0, 0, 0) scale(1);
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    -moz-transform: translate3d(0, 0, 0) scale(1);
    -ms-transform: translate3d(0, 0, 0) scale(1);
}

.rm-rotate-box {
    position: relative;
    text-align: center;
    perspective: 400px;

    .overlay-rotatebox {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, #232323 100%);
    }
}

.thumb-wrap {
    .card-side {
        background-repeat: no-repeat;
        background-position: center center;
    }
}