.products-sparktrol {
  .img-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 0.75rem;
    width: 200px;
    height: 200px;
    // overflow: hidden;
    border-width: 0.75rem;

    img {
      // border-radius: 50%;
      display: block;
      object-fit: contain;
      max-height: 80px;
    }
  }

  .service-style {
    text-align: center;

    .title {
      font-family: var(--alt-font);
      display: inline-block;
      font-weight: 500;
      margin-bottom: 5px;
      color: #333045;
    }

    p {
      @media (max-width: var(--xs)) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}